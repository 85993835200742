export const dateFormatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
});

const baseRoundedTimeFormatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  dayPeriod: 'long',
});

export const roundedTimeFormatter = {
  format: (date: Date) => {
    const parts = baseRoundedTimeFormatter.formatToParts(date);
    const dayPeriodIndex = parts.findIndex((part) => part.type === 'dayPeriod');

    if (dayPeriodIndex !== -1) {
      const dayPeriod = parts[dayPeriodIndex].value;

      if (dayPeriod === 'noon') {
        parts[dayPeriodIndex].value = 'in the afternoon';
      } else if (dayPeriod === 'midnight') {
        parts[dayPeriodIndex].value = 'in the evening';
      } else if (dayPeriod === 'at night') {
        const hour = date.getHours();
        if (hour < 12) {
          parts[dayPeriodIndex].value = 'in the morning';
        } else {
          parts[dayPeriodIndex].value = 'in the evening';
        }
      }
    }

    return parts.map((part) => part.value).join('');
  },
};
