function getBusinessDaysInMillisecondsBetweenDates(
  startDate: Date,
  endDate: Date,
): number {
  // Ensure the start date is before the end date
  if (startDate > endDate) {
    return 0;
  }

  // Check if both dates are on the same day
  if (startDate.toDateString() === endDate.toDateString()) {
    const dayOfWeek = startDate.getDay();
    // If it's a weekend, return 0
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return 0;
    }
    // Otherwise, return the difference in milliseconds on the same day
    return endDate.getTime() - startDate.getTime();
  }

  let totalMilliseconds = 0;
  const currentDate = new Date(startDate); // Create a copy of the start date

  // Special handling for the first day (partial day)
  if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
    // Not a weekend
    const endOfFirstDay = new Date(currentDate);
    endOfFirstDay.setHours(23, 59, 59, 999); // Set time to the end of the first day
    totalMilliseconds +=
      Math.min(endOfFirstDay.getTime(), endDate.getTime()) -
      currentDate.getTime();
  }

  // Move to the next day (full days in between)
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0); // Reset time to the start of the day

  // Iterate through full days between the two dates
  const startOfLastDay = new Date(endDate);
  startOfLastDay.setHours(0, 0, 0, 0); // Set time to the start of the last day

  while (currentDate < startOfLastDay) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // Weekday
      totalMilliseconds += 24 * 60 * 60 * 1000; // Add one full day's worth of milliseconds
    }
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  // Special handling for the last day (partial day)
  if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
    // Not a weekend
    totalMilliseconds +=
      endDate.getTime() -
      Math.max(startDate.getTime(), startOfLastDay.getTime());
  }

  return totalMilliseconds;
}

function getBusinessDaysBetweenDates(startDate: Date, endDate: Date): number {
  const milliseconds = getBusinessDaysInMillisecondsBetweenDates(
    startDate,
    endDate,
  );

  return milliseconds / 1000 / 60 / 60 / 24;
}

function adjustEndDateForBusinessDays(startDate: Date, endDate: Date): Date {
  const totalMilliseconds = endDate.getTime() - startDate.getTime();
  const businessDaysMilliseconds = getBusinessDaysInMillisecondsBetweenDates(
    startDate,
    endDate,
  );
  const diff = totalMilliseconds - businessDaysMilliseconds;

  // special handling for when start and end are on the same weekend days
  if (diff === totalMilliseconds) {
    const dayOfWeek = endDate.getDay();
    if (dayOfWeek === 6) {
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 2); // Move to Monday
      adjustedEndDate.setHours(0, 0, 0, 0); // Set time to the start of the day
      return adjustedEndDate;
    }
    if (dayOfWeek === 0) {
      const adjustedEndDate = new Date(endDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1); // Move to Monday
      adjustedEndDate.setHours(0, 0, 0, 0); // Set time to the start of the day
      return adjustedEndDate;
    }
  }

  return new Date(endDate.getTime() + diff);
}

// needs to be exported so these functions can be used in aggregate pipelines
export const DateUtils = {
  getBusinessDaysInMillisecondsBetweenDates,
  getBusinessDaysBetweenDates,
  adjustEndDateForBusinessDays,
};
