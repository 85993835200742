export const removeTypename = <T extends object>(data: T): T => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (key === '__typename') {
      return acc;
    }

    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null &&
      Object.keys(value).includes('__typename')
    ) {
      acc[key as keyof T] = removeTypename(value);
    } else if (Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[key as keyof T] = value.map(removeTypename);
    } else {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as T);
};
