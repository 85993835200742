const FIRST_TIMEOUT_MILLISECONDS = 1_000 * 60 * 10;
const FIRST_BACKOFF_MILLISECONDS = 1_000 * 60 * 30;
const SECOND_TIMEOUT_MILLISECONDS = 1_000 * 60 * 15;
const SECOND_BACKOFF_MILLISECONDS = 1_000 * 60 * 30;
const THIRD_TIMEOUT_MILLISECONDS = 1_000 * 60 * 15;

export const CONNECTION_RETRY_TIMEOUT_MILLISECONDS =
  FIRST_TIMEOUT_MILLISECONDS +
  FIRST_BACKOFF_MILLISECONDS +
  SECOND_TIMEOUT_MILLISECONDS +
  SECOND_BACKOFF_MILLISECONDS +
  THIRD_TIMEOUT_MILLISECONDS;

export class ContainerSiteUtils {
  static isOffline(maxOfflineMilliseconds: number, lastSync: Date): boolean {
    const now = new Date();
    const diff = now.getTime() - lastSync.getTime();

    return (
      diff > maxOfflineMilliseconds + CONNECTION_RETRY_TIMEOUT_MILLISECONDS
    );
  }

  static nextSyncDate(maxOfflineMilliseconds: number, lastSync: Date): Date {
    const nextSync = new Date(lastSync); // copy instead of mutating
    nextSync.setMilliseconds(
      nextSync.getMilliseconds() + maxOfflineMilliseconds,
    );

    return nextSync;
  }

  static offlineAt(maxOfflineMilliseconds: number, lastSync: Date): Date {
    const nextSync = new Date(lastSync); // copy instead of mutating
    nextSync.setMilliseconds(
      nextSync.getMilliseconds() +
        maxOfflineMilliseconds +
        CONNECTION_RETRY_TIMEOUT_MILLISECONDS,
    );

    return nextSync;
  }
}
